<template>
  <div class="wrap">
    <div class="box-top">
      <el-button type="primary" icon="el-icon-circle-plus-outline" size="small" @click="add">新增</el-button>
      <!-- <el-input v-model="mess" placeholder="请输入活动主题查询" @keydown.enter.native="Search" clearable>
        <i slot="prefix" class="el-input__icon el-icon-search" @click="Search"></i>
      </el-input> -->
    </div> 

    <!-- 列表 -->
    <commonTable ref="commonTable" :dataSource="tableData" :columnsList="columns" :total="total" @currentChange="currentChange" @sizeChange="sizeChange">
      <template slot="id" slot-scope="{record}">
        <span class="iconfont icon-erweima" @click="handleQR(record)"></span>
      </template>
      <template slot="action" slot-scope="{record}">
        <el-button @click="edit(record)" type="text" style="color: #00788b;">编辑</el-button>
        <el-divider direction="vertical"></el-divider>
        <el-popconfirm title="确定删除吗？" @confirm="confirm(record)">
          <el-button slot="reference" type="text" style="color: #b51c1b;">删除</el-button>
        </el-popconfirm>
      </template>
    </commonTable>

    <!-- 弹窗 -->
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="50%"
      :before-close="handleClose" center :close-on-press-escape="false" :close-on-click-modal="false">
        <study-sign-modal v-if="dialogVisible" ref="studySignModal" :oldForm="form"></study-sign-modal>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" :size="btnSize">取 消</el-button>
        <el-button type="primary" @click="submit" :size="btnSize">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 二维码弹窗 -->
    <el-dialog :title="qrtitle" :visible.sync="QRVisible" width="30%" :before-close="qrClose" center :close-on-press-escape="false" :close-on-click-modal="false">
      <div class="QRBox">
        <!-- <h2>{{qrtitle}}</h2> -->
        <vue-qr :text="imgUrl" :size="250"></vue-qr>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { findStudySignAll, addStudySign, deleteStudySign, updateStudySign } from '@/api/studySign';
import studySignModal from './studySignModal.vue';
import commonTable from '../../components/commonTable.vue';
import { myMixins } from '../../mixins/myMixins';
import VueQr from 'vue-qr';
export default {
  name: 'studySign',
  mixins: [myMixins],
  data() {
    return {
      // id: JSON.parse(sessionStorage.getItem('userInfo')).id || '',
      mess: '',
      total: 0,
      title: '',
      pageNo: 1,
      pageSize: 10,
      btnSize: 'mini',
      imgUrl: '',
      QRVisible: false,
      qrtitle: '',
      dialogVisible: false,
      tableData: [],
      form: {},
      columns: [
        {
          title: '学院名称',
          key: 'schoolName',
          align: 'center'
        },
        {
          title: '专业类型',
          key: 'type',
          align: 'center'
        },
        {
          title: '专业二维码',
          key: 'id',
          align: 'center',
          scopedSlots: {
            columnSlot: 'id'
          },
        },
        {
          title: '专业名称',
          key: 'majorName',
          align: 'center',
        },
        {
          title: '专业介绍',
          key: 'introduction',
          align: 'center',
          width: 900,
        },
        {
          title: '操作',
          key: 'action',
          align: 'center',
          scopedSlots: {
            columnSlot: 'action'
          },
          width: 120,
        },
      ]
    }
  },
  components: {
    commonTable,
    studySignModal,
    VueQr
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      let data = {
        // title: this.mess||undefined,
        pageNo: this.pageNo,
        pageSize: this.pageSize
      }
      findStudySignAll(data).then(res => {
        if(res.success) {
          if(res.result.records.length == 0 && res.result.total != 0) {
            this.pageNo = res.result.pages;
            this.loadData();
          }else {
            this.tableData = res.result.records;
            this.total = res.result.total;
            this.pageNo = res.result.current;
            this.pageSize = res.result.size; 
          }
          this.$nextTick(() => {
            this.$refs.commonTable.$refs.table.doLayout();
          })
        }
      })
    },
    Search() {
      this.pageNo = 1;
      this.loadData();
    },
    submit() {
      this.$refs.studySignModal.$refs.form.validate((valid) => {
        let formInfo = this.$refs.studySignModal.form
        let param = JSON.parse(JSON.stringify(formInfo));
        if(!valid) {
          return 
        }else{
          let request = null;
          if(formInfo.id) {
            request = updateStudySign(param);
          }else {
            // param.id = this.id//用户id
            request = addStudySign(param)
          }
          request.then(res => {
            if(res.success) {
              this.$message.success(res.message);
              this.dialogVisible = false;
              this.loadData();
            }else {
              this.$message.error(res.message);
            }
          })
        }
      })
    },
    handleClose() {
      this.dialogVisible = false;
    },
    add() {
      this.form = {};
      this.dialogVisible = true;
      this.title = '新增学业签';
    },
    edit(e) {
      this.form = e;
      this.dialogVisible = true;
      this.title = '编辑学业签';
    },
    del(e) {
      deleteStudySign({id: e.id}).then(res => {
        if(res.success) {
          this.$message.success(res.message);
          this.loadData();
        }else {
          this.$message.error(res.message);
        }
      })
    },
    confirm(e) {
      this.del(e);
    },
    handleQR(e) {
      this.qrtitle = e.majorName;
      this.imgUrl = 'http://www.yuanhuishang.com/yhs/xyqcode?code=' + e.id;
      this.QRVisible = true;
    },
    qrClose() {
      this.QRVisible = false;
    },
  }
}
</script>

<style scoped lang="less">
  @import '../../assets/css/commonMenu.less';
  .iconfont{
    font-size: 18px;
    color: skyblue;
  }
  .QRBox{
    text-align: center;
  }
</style>